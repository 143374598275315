exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-angular-developer-js": () => import("./../../../src/pages/angular-developer.js" /* webpackChunkName: "component---src-pages-angular-developer-js" */),
  "component---src-pages-angularjs-js": () => import("./../../../src/pages/angularjs.js" /* webpackChunkName: "component---src-pages-angularjs-js" */),
  "component---src-pages-apps-aichatbotpro-privacy-policy-js": () => import("./../../../src/pages/apps/aichatbotpro/privacy-policy.js" /* webpackChunkName: "component---src-pages-apps-aichatbotpro-privacy-policy-js" */),
  "component---src-pages-apps-aichatbotpro-terms-of-use-js": () => import("./../../../src/pages/apps/aichatbotpro/terms-of-use.js" /* webpackChunkName: "component---src-pages-apps-aichatbotpro-terms-of-use-js" */),
  "component---src-pages-apps-artscribe-privacy-policy-js": () => import("./../../../src/pages/apps/artscribe/privacy-policy.js" /* webpackChunkName: "component---src-pages-apps-artscribe-privacy-policy-js" */),
  "component---src-pages-apps-artscribe-terms-of-use-js": () => import("./../../../src/pages/apps/artscribe/terms-of-use.js" /* webpackChunkName: "component---src-pages-apps-artscribe-terms-of-use-js" */),
  "component---src-pages-apps-cbsesmarttutor-privacy-policy-js": () => import("./../../../src/pages/apps/cbsesmarttutor/privacy-policy.js" /* webpackChunkName: "component---src-pages-apps-cbsesmarttutor-privacy-policy-js" */),
  "component---src-pages-apps-cbsesmarttutor-terms-of-use-js": () => import("./../../../src/pages/apps/cbsesmarttutor/terms-of-use.js" /* webpackChunkName: "component---src-pages-apps-cbsesmarttutor-terms-of-use-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-case-studies-riyaz-js": () => import("./../../../src/pages/case-studies/riyaz.js" /* webpackChunkName: "component---src-pages-case-studies-riyaz-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-contact-us-thank-you-js": () => import("./../../../src/pages/contact-us/thank-you.js" /* webpackChunkName: "component---src-pages-contact-us-thank-you-js" */),
  "component---src-pages-ebooks-enterprise-web-application-guide-index-js": () => import("./../../../src/pages/ebooks/enterprise-web-application-guide/index.js" /* webpackChunkName: "component---src-pages-ebooks-enterprise-web-application-guide-index-js" */),
  "component---src-pages-ebooks-enterprise-web-application-guide-page-01-js": () => import("./../../../src/pages/ebooks/enterprise-web-application-guide/page-01.js" /* webpackChunkName: "component---src-pages-ebooks-enterprise-web-application-guide-page-01-js" */),
  "component---src-pages-ebooks-enterprise-web-application-guide-page-02-js": () => import("./../../../src/pages/ebooks/enterprise-web-application-guide/page-02.js" /* webpackChunkName: "component---src-pages-ebooks-enterprise-web-application-guide-page-02-js" */),
  "component---src-pages-ebooks-enterprise-web-application-guide-page-03-js": () => import("./../../../src/pages/ebooks/enterprise-web-application-guide/page-03.js" /* webpackChunkName: "component---src-pages-ebooks-enterprise-web-application-guide-page-03-js" */),
  "component---src-pages-ebooks-how-to-build-saas-product-index-js": () => import("./../../../src/pages/ebooks/how-to-build-saas-product/index.js" /* webpackChunkName: "component---src-pages-ebooks-how-to-build-saas-product-index-js" */),
  "component---src-pages-ebooks-how-to-build-saas-product-page-01-js": () => import("./../../../src/pages/ebooks/how-to-build-saas-product/page-01.js" /* webpackChunkName: "component---src-pages-ebooks-how-to-build-saas-product-page-01-js" */),
  "component---src-pages-ebooks-how-to-build-saas-product-page-02-js": () => import("./../../../src/pages/ebooks/how-to-build-saas-product/page-02.js" /* webpackChunkName: "component---src-pages-ebooks-how-to-build-saas-product-page-02-js" */),
  "component---src-pages-ebooks-how-to-build-saas-product-page-03-js": () => import("./../../../src/pages/ebooks/how-to-build-saas-product/page-03.js" /* webpackChunkName: "component---src-pages-ebooks-how-to-build-saas-product-page-03-js" */),
  "component---src-pages-ebooks-js": () => import("./../../../src/pages/ebooks.js" /* webpackChunkName: "component---src-pages-ebooks-js" */),
  "component---src-pages-ebooks-offshore-developer-rates-index-js": () => import("./../../../src/pages/ebooks/offshore-developer-rates/index.js" /* webpackChunkName: "component---src-pages-ebooks-offshore-developer-rates-index-js" */),
  "component---src-pages-ebooks-offshore-developer-rates-page-01-js": () => import("./../../../src/pages/ebooks/offshore-developer-rates/page-01.js" /* webpackChunkName: "component---src-pages-ebooks-offshore-developer-rates-page-01-js" */),
  "component---src-pages-ebooks-offshore-developer-rates-page-02-js": () => import("./../../../src/pages/ebooks/offshore-developer-rates/page-02.js" /* webpackChunkName: "component---src-pages-ebooks-offshore-developer-rates-page-02-js" */),
  "component---src-pages-ebooks-offshore-developer-rates-page-03-js": () => import("./../../../src/pages/ebooks/offshore-developer-rates/page-03.js" /* webpackChunkName: "component---src-pages-ebooks-offshore-developer-rates-page-03-js" */),
  "component---src-pages-ebooks-saas-product-launch-checklist-index-js": () => import("./../../../src/pages/ebooks/saas-product-launch-checklist/index.js" /* webpackChunkName: "component---src-pages-ebooks-saas-product-launch-checklist-index-js" */),
  "component---src-pages-ebooks-software-development-outsourcing-all-the-essentials-in-one-place-index-js": () => import("./../../../src/pages/ebooks/software-development-outsourcing-all-the-essentials-in-one-place/index.js" /* webpackChunkName: "component---src-pages-ebooks-software-development-outsourcing-all-the-essentials-in-one-place-index-js" */),
  "component---src-pages-ebooks-software-development-outsourcing-all-the-essentials-in-one-place-page-01-js": () => import("./../../../src/pages/ebooks/software-development-outsourcing-all-the-essentials-in-one-place/page-01.js" /* webpackChunkName: "component---src-pages-ebooks-software-development-outsourcing-all-the-essentials-in-one-place-page-01-js" */),
  "component---src-pages-ebooks-software-development-outsourcing-all-the-essentials-in-one-place-page-02-js": () => import("./../../../src/pages/ebooks/software-development-outsourcing-all-the-essentials-in-one-place/page-02.js" /* webpackChunkName: "component---src-pages-ebooks-software-development-outsourcing-all-the-essentials-in-one-place-page-02-js" */),
  "component---src-pages-ebooks-software-development-outsourcing-all-the-essentials-in-one-place-page-03-js": () => import("./../../../src/pages/ebooks/software-development-outsourcing-all-the-essentials-in-one-place/page-03.js" /* webpackChunkName: "component---src-pages-ebooks-software-development-outsourcing-all-the-essentials-in-one-place-page-03-js" */),
  "component---src-pages-ebooks-software-requirements-specification-document-template-index-js": () => import("./../../../src/pages/ebooks/software-requirements-specification-document-template/index.js" /* webpackChunkName: "component---src-pages-ebooks-software-requirements-specification-document-template-index-js" */),
  "component---src-pages-ebooks-software-requirements-specification-document-template-page-01-js": () => import("./../../../src/pages/ebooks/software-requirements-specification-document-template/page-01.js" /* webpackChunkName: "component---src-pages-ebooks-software-requirements-specification-document-template-page-01-js" */),
  "component---src-pages-ebooks-software-requirements-specification-document-template-page-02-js": () => import("./../../../src/pages/ebooks/software-requirements-specification-document-template/page-02.js" /* webpackChunkName: "component---src-pages-ebooks-software-requirements-specification-document-template-page-02-js" */),
  "component---src-pages-ebooks-software-requirements-specification-document-template-page-03-js": () => import("./../../../src/pages/ebooks/software-requirements-specification-document-template/page-03.js" /* webpackChunkName: "component---src-pages-ebooks-software-requirements-specification-document-template-page-03-js" */),
  "component---src-pages-ebooks-the-ultimate-startup-guide-to-product-development-index-js": () => import("./../../../src/pages/ebooks/the-ultimate-startup-guide-to-product-development/index.js" /* webpackChunkName: "component---src-pages-ebooks-the-ultimate-startup-guide-to-product-development-index-js" */),
  "component---src-pages-ebooks-the-ultimate-startup-guide-to-product-development-page-01-js": () => import("./../../../src/pages/ebooks/the-ultimate-startup-guide-to-product-development/page-01.js" /* webpackChunkName: "component---src-pages-ebooks-the-ultimate-startup-guide-to-product-development-page-01-js" */),
  "component---src-pages-ebooks-the-ultimate-startup-guide-to-product-development-page-02-js": () => import("./../../../src/pages/ebooks/the-ultimate-startup-guide-to-product-development/page-02.js" /* webpackChunkName: "component---src-pages-ebooks-the-ultimate-startup-guide-to-product-development-page-02-js" */),
  "component---src-pages-ebooks-the-ultimate-startup-guide-to-product-development-page-03-js": () => import("./../../../src/pages/ebooks/the-ultimate-startup-guide-to-product-development/page-03.js" /* webpackChunkName: "component---src-pages-ebooks-the-ultimate-startup-guide-to-product-development-page-03-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hire-us-hire-mobile-app-developers-hire-android-developer-js": () => import("./../../../src/pages/hire-us/hire-mobile-app-developers/hire-android-developer.js" /* webpackChunkName: "component---src-pages-hire-us-hire-mobile-app-developers-hire-android-developer-js" */),
  "component---src-pages-hire-us-hire-mobile-app-developers-hire-ar-developer-js": () => import("./../../../src/pages/hire-us/hire-mobile-app-developers/hire-ar-developer.js" /* webpackChunkName: "component---src-pages-hire-us-hire-mobile-app-developers-hire-ar-developer-js" */),
  "component---src-pages-hire-us-hire-mobile-app-developers-hire-flutter-developer-js": () => import("./../../../src/pages/hire-us/hire-mobile-app-developers/hire-flutter-developer.js" /* webpackChunkName: "component---src-pages-hire-us-hire-mobile-app-developers-hire-flutter-developer-js" */),
  "component---src-pages-hire-us-hire-mobile-app-developers-hire-ionic-developer-js": () => import("./../../../src/pages/hire-us/hire-mobile-app-developers/hire-ionic-developer.js" /* webpackChunkName: "component---src-pages-hire-us-hire-mobile-app-developers-hire-ionic-developer-js" */),
  "component---src-pages-hire-us-hire-mobile-app-developers-hire-ios-developer-js": () => import("./../../../src/pages/hire-us/hire-mobile-app-developers/hire-ios-developer.js" /* webpackChunkName: "component---src-pages-hire-us-hire-mobile-app-developers-hire-ios-developer-js" */),
  "component---src-pages-hire-us-hire-mobile-app-developers-hire-react-developer-js": () => import("./../../../src/pages/hire-us/hire-mobile-app-developers/hire-react-developer.js" /* webpackChunkName: "component---src-pages-hire-us-hire-mobile-app-developers-hire-react-developer-js" */),
  "component---src-pages-hire-us-hire-mobile-app-developers-js": () => import("./../../../src/pages/hire-us/hire-mobile-app-developers.js" /* webpackChunkName: "component---src-pages-hire-us-hire-mobile-app-developers-js" */),
  "component---src-pages-hire-us-hire-voice-developers-hire-alexa-skill-developer-js": () => import("./../../../src/pages/hire-us/hire-voice-developers/hire-alexa-skill-developer.js" /* webpackChunkName: "component---src-pages-hire-us-hire-voice-developers-hire-alexa-skill-developer-js" */),
  "component---src-pages-hire-us-hire-voice-developers-hire-google-actions-developer-js": () => import("./../../../src/pages/hire-us/hire-voice-developers/hire-google-actions-developer.js" /* webpackChunkName: "component---src-pages-hire-us-hire-voice-developers-hire-google-actions-developer-js" */),
  "component---src-pages-hire-us-hire-voice-developers-js": () => import("./../../../src/pages/hire-us/hire-voice-developers.js" /* webpackChunkName: "component---src-pages-hire-us-hire-voice-developers-js" */),
  "component---src-pages-hire-us-hire-web-developer-hire-angular-developer-js": () => import("./../../../src/pages/hire-us/hire-web-developer/hire-angular-developer.js" /* webpackChunkName: "component---src-pages-hire-us-hire-web-developer-hire-angular-developer-js" */),
  "component---src-pages-hire-us-hire-web-developer-hire-laravel-developer-js": () => import("./../../../src/pages/hire-us/hire-web-developer/hire-laravel-developer.js" /* webpackChunkName: "component---src-pages-hire-us-hire-web-developer-hire-laravel-developer-js" */),
  "component---src-pages-hire-us-hire-web-developer-hire-node-developer-js": () => import("./../../../src/pages/hire-us/hire-web-developer/hire-node-developer.js" /* webpackChunkName: "component---src-pages-hire-us-hire-web-developer-hire-node-developer-js" */),
  "component---src-pages-hire-us-hire-web-developer-hire-python-developer-js": () => import("./../../../src/pages/hire-us/hire-web-developer/hire-python-developer.js" /* webpackChunkName: "component---src-pages-hire-us-hire-web-developer-hire-python-developer-js" */),
  "component---src-pages-hire-us-hire-web-developer-hire-reactjs-developer-js": () => import("./../../../src/pages/hire-us/hire-web-developer/hire-reactjs-developer.js" /* webpackChunkName: "component---src-pages-hire-us-hire-web-developer-hire-reactjs-developer-js" */),
  "component---src-pages-hire-us-hire-web-developer-hire-vuejs-developer-js": () => import("./../../../src/pages/hire-us/hire-web-developer/hire-vuejs-developer.js" /* webpackChunkName: "component---src-pages-hire-us-hire-web-developer-hire-vuejs-developer-js" */),
  "component---src-pages-hire-us-hire-web-developer-js": () => import("./../../../src/pages/hire-us/hire-web-developer.js" /* webpackChunkName: "component---src-pages-hire-us-hire-web-developer-js" */),
  "component---src-pages-hire-us-js": () => import("./../../../src/pages/hire-us.js" /* webpackChunkName: "component---src-pages-hire-us-js" */),
  "component---src-pages-hire-us-thank-you-js": () => import("./../../../src/pages/hire-us/thank-you.js" /* webpackChunkName: "component---src-pages-hire-us-thank-you-js" */),
  "component---src-pages-how-we-communicate-js": () => import("./../../../src/pages/how-we-communicate.js" /* webpackChunkName: "component---src-pages-how-we-communicate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-we-serve-automobiles-js": () => import("./../../../src/pages/industries-we-serve/automobiles.js" /* webpackChunkName: "component---src-pages-industries-we-serve-automobiles-js" */),
  "component---src-pages-industries-we-serve-education-js": () => import("./../../../src/pages/industries-we-serve/education.js" /* webpackChunkName: "component---src-pages-industries-we-serve-education-js" */),
  "component---src-pages-industries-we-serve-js": () => import("./../../../src/pages/industries-we-serve.js" /* webpackChunkName: "component---src-pages-industries-we-serve-js" */),
  "component---src-pages-industries-we-serve-media-and-entertainment-js": () => import("./../../../src/pages/industries-we-serve/media-and-entertainment.js" /* webpackChunkName: "component---src-pages-industries-we-serve-media-and-entertainment-js" */),
  "component---src-pages-life-at-trt-js": () => import("./../../../src/pages/life-at-trt.js" /* webpackChunkName: "component---src-pages-life-at-trt-js" */),
  "component---src-pages-node-developer-2-js": () => import("./../../../src/pages/node-developer2.js" /* webpackChunkName: "component---src-pages-node-developer-2-js" */),
  "component---src-pages-our-clients-js": () => import("./../../../src/pages/our-clients.js" /* webpackChunkName: "component---src-pages-our-clients-js" */),
  "component---src-pages-portfolio-business-card-scanner-app-sharit-js": () => import("./../../../src/pages/portfolio/business-card-scanner-app/sharit.js" /* webpackChunkName: "component---src-pages-portfolio-business-card-scanner-app-sharit-js" */),
  "component---src-pages-portfolio-carjasoos-js": () => import("./../../../src/pages/portfolio/carjasoos.js" /* webpackChunkName: "component---src-pages-portfolio-carjasoos-js" */),
  "component---src-pages-portfolio-dating-app-onmybehalf-js": () => import("./../../../src/pages/portfolio/dating-app/onmybehalf.js" /* webpackChunkName: "component---src-pages-portfolio-dating-app-onmybehalf-js" */),
  "component---src-pages-portfolio-digital-pdfs-handtyped-js": () => import("./../../../src/pages/portfolio/digital-pdfs/handtyped.js" /* webpackChunkName: "component---src-pages-portfolio-digital-pdfs-handtyped-js" */),
  "component---src-pages-portfolio-document-management-system-js": () => import("./../../../src/pages/portfolio/document-management-system.js" /* webpackChunkName: "component---src-pages-portfolio-document-management-system-js" */),
  "component---src-pages-portfolio-homecare-management-app-caresharerx-js": () => import("./../../../src/pages/portfolio/homecare-management-app/caresharerx.js" /* webpackChunkName: "component---src-pages-portfolio-homecare-management-app-caresharerx-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-language-learning-app-kidslipi-js": () => import("./../../../src/pages/portfolio/language-learning-app/kidslipi.js" /* webpackChunkName: "component---src-pages-portfolio-language-learning-app-kidslipi-js" */),
  "component---src-pages-portfolio-mangobilling-js": () => import("./../../../src/pages/portfolio/mangobilling.js" /* webpackChunkName: "component---src-pages-portfolio-mangobilling-js" */),
  "component---src-pages-portfolio-maxwell-js": () => import("./../../../src/pages/portfolio/maxwell.js" /* webpackChunkName: "component---src-pages-portfolio-maxwell-js" */),
  "component---src-pages-portfolio-medicaldomainapplication-js": () => import("./../../../src/pages/portfolio/medicaldomainapplication.js" /* webpackChunkName: "component---src-pages-portfolio-medicaldomainapplication-js" */),
  "component---src-pages-portfolio-mobile-app-installer-miles-js": () => import("./../../../src/pages/portfolio/mobile-app/installer-miles.js" /* webpackChunkName: "component---src-pages-portfolio-mobile-app-installer-miles-js" */),
  "component---src-pages-portfolio-music-learning-app-riyaz-js": () => import("./../../../src/pages/portfolio/music-learning-app/riyaz.js" /* webpackChunkName: "component---src-pages-portfolio-music-learning-app-riyaz-js" */),
  "component---src-pages-portfolio-newleadprospect-js": () => import("./../../../src/pages/portfolio/newleadprospect.js" /* webpackChunkName: "component---src-pages-portfolio-newleadprospect-js" */),
  "component---src-pages-portfolio-nextpond-js": () => import("./../../../src/pages/portfolio/nextpond.js" /* webpackChunkName: "component---src-pages-portfolio-nextpond-js" */),
  "component---src-pages-portfolio-nins-js": () => import("./../../../src/pages/portfolio/nins.js" /* webpackChunkName: "component---src-pages-portfolio-nins-js" */),
  "component---src-pages-portfolio-onemilliondream-js": () => import("./../../../src/pages/portfolio/onemilliondream.js" /* webpackChunkName: "component---src-pages-portfolio-onemilliondream-js" */),
  "component---src-pages-portfolio-pop-world-tv-js": () => import("./../../../src/pages/portfolio/pop-world-tv.js" /* webpackChunkName: "component---src-pages-portfolio-pop-world-tv-js" */),
  "component---src-pages-portfolio-quran-learning-app-quranic-js": () => import("./../../../src/pages/portfolio/quran-learning-app/quranic.js" /* webpackChunkName: "component---src-pages-portfolio-quran-learning-app-quranic-js" */),
  "component---src-pages-portfolio-religious-app-daily-muslim-js": () => import("./../../../src/pages/portfolio/religious-app/daily-muslim.js" /* webpackChunkName: "component---src-pages-portfolio-religious-app-daily-muslim-js" */),
  "component---src-pages-portfolio-rentalq-js": () => import("./../../../src/pages/portfolio/rentalq.js" /* webpackChunkName: "component---src-pages-portfolio-rentalq-js" */),
  "component---src-pages-portfolio-smoovr-js": () => import("./../../../src/pages/portfolio/smoovr.js" /* webpackChunkName: "component---src-pages-portfolio-smoovr-js" */),
  "component---src-pages-portfolio-taxi-booking-app-taxilink-js": () => import("./../../../src/pages/portfolio/taxi-booking-app/taxilink.js" /* webpackChunkName: "component---src-pages-portfolio-taxi-booking-app-taxilink-js" */),
  "component---src-pages-portfolio-valuables-sharing-app-sharing-o-js": () => import("./../../../src/pages/portfolio/valuables-sharing-app/sharing-o.js" /* webpackChunkName: "component---src-pages-portfolio-valuables-sharing-app-sharing-o-js" */),
  "component---src-pages-portfolio-worlds-first-voice-gifting-app-autumn-whispers-js": () => import("./../../../src/pages/portfolio/world’s-first-voice-gifting-app/autumn-whispers.js" /* webpackChunkName: "component---src-pages-portfolio-worlds-first-voice-gifting-app-autumn-whispers-js" */),
  "component---src-pages-portfolio-xclusive-js": () => import("./../../../src/pages/portfolio/xclusive.js" /* webpackChunkName: "component---src-pages-portfolio-xclusive-js" */),
  "component---src-pages-post-delivery-support-js": () => import("./../../../src/pages/post-delivery-support.js" /* webpackChunkName: "component---src-pages-post-delivery-support-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-project-management-process-js": () => import("./../../../src/pages/project-management-process.js" /* webpackChunkName: "component---src-pages-project-management-process-js" */),
  "component---src-pages-services-aiagents-index-js": () => import("./../../../src/pages/services/aiagents/index.js" /* webpackChunkName: "component---src-pages-services-aiagents-index-js" */),
  "component---src-pages-services-aichatbot-index-js": () => import("./../../../src/pages/services/aichatbot/index.js" /* webpackChunkName: "component---src-pages-services-aichatbot-index-js" */),
  "component---src-pages-services-aiconsulting-index-js": () => import("./../../../src/pages/services/aiconsulting/index.js" /* webpackChunkName: "component---src-pages-services-aiconsulting-index-js" */),
  "component---src-pages-services-aidevelopment-index-js": () => import("./../../../src/pages/services/aidevelopment/index.js" /* webpackChunkName: "component---src-pages-services-aidevelopment-index-js" */),
  "component---src-pages-services-devops-ci-cd-implementation-and-management-js": () => import("./../../../src/pages/services/devops/ci-cd-implementation-and-management.js" /* webpackChunkName: "component---src-pages-services-devops-ci-cd-implementation-and-management-js" */),
  "component---src-pages-services-devops-devops-consulting-js": () => import("./../../../src/pages/services/devops/devops-consulting.js" /* webpackChunkName: "component---src-pages-services-devops-devops-consulting-js" */),
  "component---src-pages-services-devops-infrastructure-as-a-code-js": () => import("./../../../src/pages/services/devops/infrastructure-as-a-code.js" /* webpackChunkName: "component---src-pages-services-devops-infrastructure-as-a-code-js" */),
  "component---src-pages-services-devops-infrastructure-management-and-monitoring-js": () => import("./../../../src/pages/services/devops/infrastructure-management-and-monitoring.js" /* webpackChunkName: "component---src-pages-services-devops-infrastructure-management-and-monitoring-js" */),
  "component---src-pages-services-devops-js": () => import("./../../../src/pages/services/devops.js" /* webpackChunkName: "component---src-pages-services-devops-js" */),
  "component---src-pages-services-generativeai-index-js": () => import("./../../../src/pages/services/generativeai/index.js" /* webpackChunkName: "component---src-pages-services-generativeai-index-js" */),
  "component---src-pages-services-headless-development-directus-js": () => import("./../../../src/pages/services/headless-development/directus.js" /* webpackChunkName: "component---src-pages-services-headless-development-directus-js" */),
  "component---src-pages-services-headless-development-js": () => import("./../../../src/pages/services/headless-development.js" /* webpackChunkName: "component---src-pages-services-headless-development-js" */),
  "component---src-pages-services-headless-development-strapi-development-js": () => import("./../../../src/pages/services/headless-development/strapi-development.js" /* webpackChunkName: "component---src-pages-services-headless-development-strapi-development-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-llmdevelopment-index-js": () => import("./../../../src/pages/services/llmdevelopment/index.js" /* webpackChunkName: "component---src-pages-services-llmdevelopment-index-js" */),
  "component---src-pages-services-mobile-development-android-development-js": () => import("./../../../src/pages/services/mobile-development/android-development.js" /* webpackChunkName: "component---src-pages-services-mobile-development-android-development-js" */),
  "component---src-pages-services-mobile-development-ar-development-js": () => import("./../../../src/pages/services/mobile-development/ar-development.js" /* webpackChunkName: "component---src-pages-services-mobile-development-ar-development-js" */),
  "component---src-pages-services-mobile-development-flutter-development-js": () => import("./../../../src/pages/services/mobile-development/flutter-development.js" /* webpackChunkName: "component---src-pages-services-mobile-development-flutter-development-js" */),
  "component---src-pages-services-mobile-development-ionic-development-js": () => import("./../../../src/pages/services/mobile-development/ionic-development.js" /* webpackChunkName: "component---src-pages-services-mobile-development-ionic-development-js" */),
  "component---src-pages-services-mobile-development-ios-development-js": () => import("./../../../src/pages/services/mobile-development/ios-development.js" /* webpackChunkName: "component---src-pages-services-mobile-development-ios-development-js" */),
  "component---src-pages-services-mobile-development-js": () => import("./../../../src/pages/services/mobile-development.js" /* webpackChunkName: "component---src-pages-services-mobile-development-js" */),
  "component---src-pages-services-mobile-development-react-native-development-js": () => import("./../../../src/pages/services/mobile-development/react-native-development.js" /* webpackChunkName: "component---src-pages-services-mobile-development-react-native-development-js" */),
  "component---src-pages-services-new-devops-index-js": () => import("./../../../src/pages/services/new-devops/index.js" /* webpackChunkName: "component---src-pages-services-new-devops-index-js" */),
  "component---src-pages-services-new-headless-cms-index-js": () => import("./../../../src/pages/services/new-headless-cms/index.js" /* webpackChunkName: "component---src-pages-services-new-headless-cms-index-js" */),
  "component---src-pages-services-new-mobile-development-index-js": () => import("./../../../src/pages/services/new-mobile-development/index.js" /* webpackChunkName: "component---src-pages-services-new-mobile-development-index-js" */),
  "component---src-pages-services-new-quality-engineering-index-js": () => import("./../../../src/pages/services/new-quality-engineering/index.js" /* webpackChunkName: "component---src-pages-services-new-quality-engineering-index-js" */),
  "component---src-pages-services-new-web-development-index-js": () => import("./../../../src/pages/services/new-web-development/index.js" /* webpackChunkName: "component---src-pages-services-new-web-development-index-js" */),
  "component---src-pages-services-software-testing-api-testing-js": () => import("./../../../src/pages/services/software-testing/api-testing.js" /* webpackChunkName: "component---src-pages-services-software-testing-api-testing-js" */),
  "component---src-pages-services-software-testing-js": () => import("./../../../src/pages/services/software-testing.js" /* webpackChunkName: "component---src-pages-services-software-testing-js" */),
  "component---src-pages-services-software-testing-microservices-testing-js": () => import("./../../../src/pages/services/software-testing/microservices-testing.js" /* webpackChunkName: "component---src-pages-services-software-testing-microservices-testing-js" */),
  "component---src-pages-services-software-testing-performance-testing-js": () => import("./../../../src/pages/services/software-testing/performance-testing.js" /* webpackChunkName: "component---src-pages-services-software-testing-performance-testing-js" */),
  "component---src-pages-services-software-testing-security-testing-js": () => import("./../../../src/pages/services/software-testing/security-testing.js" /* webpackChunkName: "component---src-pages-services-software-testing-security-testing-js" */),
  "component---src-pages-services-software-testing-test-automation-js": () => import("./../../../src/pages/services/software-testing/test-automation.js" /* webpackChunkName: "component---src-pages-services-software-testing-test-automation-js" */),
  "component---src-pages-services-uiux-development-js": () => import("./../../../src/pages/services/uiux-development.js" /* webpackChunkName: "component---src-pages-services-uiux-development-js" */),
  "component---src-pages-services-voice-assistant-applications-development-alexa-skill-development-js": () => import("./../../../src/pages/services/voice-assistant-applications-development/alexa-skill-development.js" /* webpackChunkName: "component---src-pages-services-voice-assistant-applications-development-alexa-skill-development-js" */),
  "component---src-pages-services-voice-assistant-applications-development-google-actions-development-js": () => import("./../../../src/pages/services/voice-assistant-applications-development/google-actions-development.js" /* webpackChunkName: "component---src-pages-services-voice-assistant-applications-development-google-actions-development-js" */),
  "component---src-pages-services-voice-assistant-applications-development-js": () => import("./../../../src/pages/services/voice-assistant-applications-development.js" /* webpackChunkName: "component---src-pages-services-voice-assistant-applications-development-js" */),
  "component---src-pages-services-web-development-angular-development-js": () => import("./../../../src/pages/services/web-development/angular-development.js" /* webpackChunkName: "component---src-pages-services-web-development-angular-development-js" */),
  "component---src-pages-services-web-development-js": () => import("./../../../src/pages/services/web-development.js" /* webpackChunkName: "component---src-pages-services-web-development-js" */),
  "component---src-pages-services-web-development-laravel-development-js": () => import("./../../../src/pages/services/web-development/laravel-development.js" /* webpackChunkName: "component---src-pages-services-web-development-laravel-development-js" */),
  "component---src-pages-services-web-development-low-code-development-js": () => import("./../../../src/pages/services/web-development/low-code-development.js" /* webpackChunkName: "component---src-pages-services-web-development-low-code-development-js" */),
  "component---src-pages-services-web-development-python-development-js": () => import("./../../../src/pages/services/web-development/python-development.js" /* webpackChunkName: "component---src-pages-services-web-development-python-development-js" */),
  "component---src-pages-services-web-development-reactjs-development-js": () => import("./../../../src/pages/services/web-development/reactjs-development.js" /* webpackChunkName: "component---src-pages-services-web-development-reactjs-development-js" */),
  "component---src-pages-services-web-development-vuejs-development-js": () => import("./../../../src/pages/services/web-development/vuejs-development.js" /* webpackChunkName: "component---src-pages-services-web-development-vuejs-development-js" */),
  "component---src-pages-solutions-custom-crm-development-js": () => import("./../../../src/pages/solutions/custom-crm-development.js" /* webpackChunkName: "component---src-pages-solutions-custom-crm-development-js" */),
  "component---src-pages-solutions-education-software-development-js": () => import("./../../../src/pages/solutions/education-software-development.js" /* webpackChunkName: "component---src-pages-solutions-education-software-development-js" */),
  "component---src-pages-solutions-enterprise-app-development-services-js": () => import("./../../../src/pages/solutions/enterprise-app-development-services.js" /* webpackChunkName: "component---src-pages-solutions-enterprise-app-development-services-js" */),
  "component---src-pages-solutions-entertainment-app-development-js": () => import("./../../../src/pages/solutions/entertainment-app-development.js" /* webpackChunkName: "component---src-pages-solutions-entertainment-app-development-js" */),
  "component---src-pages-solutions-fintech-software-development-js": () => import("./../../../src/pages/solutions/fintech-software-development.js" /* webpackChunkName: "component---src-pages-solutions-fintech-software-development-js" */),
  "component---src-pages-solutions-fitness-app-development-js": () => import("./../../../src/pages/solutions/fitness-app-development.js" /* webpackChunkName: "component---src-pages-solutions-fitness-app-development-js" */),
  "component---src-pages-solutions-healthcare-software-development-js": () => import("./../../../src/pages/solutions/healthcare-software-development.js" /* webpackChunkName: "component---src-pages-solutions-healthcare-software-development-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-solutions-mvp-development-services-js": () => import("./../../../src/pages/solutions/mvp-development-services.js" /* webpackChunkName: "component---src-pages-solutions-mvp-development-services-js" */),
  "component---src-pages-solutions-software-development-services-for-startups-js": () => import("./../../../src/pages/solutions/software-development-services-for-startups.js" /* webpackChunkName: "component---src-pages-solutions-software-development-services-for-startups-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-career-template-js": () => import("./../../../src/templates/career-template.js" /* webpackChunkName: "component---src-templates-career-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

