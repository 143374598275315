/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import ReactGA from 'react-ga'
import { jsonLDConfigs } from './src/content/trt-seo-json-ld'
import { setJSONLD } from './src/components/set-json-ld'

ReactGA.initialize(
  [
    {
      trackingId: process.env.GTM_TRACKING_ID,
      gaOptions: { name: 'gatsby-gdpr-google-tagmanager' },
    },
  ],
  {
    debug: false,
  }
)

export const onRouteUpdate = async (state) => {
  ReactGA.set({ page: state.location.pathname })
  ReactGA.pageview(state.location.pathname)
  ReactGA.set({ anonymizeIp: 1 })

  // Setting json-ld data
  await setJSONLD(state)
}

export const onServiceWorkerUpdateReady = () => {
  console.log(
    '%c --- onServiceWorkerUpdateReady: Code has been updated.',
    'color: blue'
  )
}

// import './src/css/bootstrap/scss/bootstrap.scss';
import 'bootstrap/scss/bootstrap.scss'
import './src/css/variables.scss'
import './src/css/main-content.scss'
import './src/css/responsive.scss'
